import { PaginationData } from '../models/pagination-data';

/** Email pattern. */
export const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

/** Time pattern. */
export const TIME_PATTERN = '^(0?[1-9]|1[012]):[0-5][0-9] [apAP][mM]$';

/** Phone mask. */
export const PHONE_MASK = '(000)000-0000';

/** Debounce time in ms. */
export const DEBOUNCE_TIME = 1000;

/** Date format. */
export const dateFormat = 'M/d/yy';

/** Max date. */
export const MAX_DATE = new Date(9999, 12, 31);

/** Time format. */
export const timeFormat = 'h:mm a';

/** List of states. */
export const STATES = [
	'AK',
	'AL',
	'AR',
	'AZ',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	'MI',
	'MN',
	'MO',
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VA',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY',
];

/** Table names. */
export const TABLE_NAMES = {
	customers: 'customers',
	devices: 'devices',
	buildings: 'buildings',
	proposals: 'proposals',
	invoices: 'invoices',
	inspections: 'inspections',
	periodic: 'periodic',
	dobNow: 'dobNow',
	contactsMaintenanceCompany: 'contactsMaintenanceCompany',
	contactsManagementCompany: 'contactsManagementCompany',
	contactsInspector: 'contactsInspector',
	customerProposals: 'customerProposals',
	customerInvoices: 'customerInvoices',
	customerInspections: 'customerInspections',
	customerPeriodic: 'customerPeriodic',
	customerDobNow: 'customerDobNow',
	customerDevices: 'customerDevices',
	dashboard: 'dashboard',
	customersDashboard: 'customersDashboard',
} as const;

/** Max size of page supported by the API. */
const MAX_SERVER_PAGE_SIZE = 100;

/**
 * TODO (ASECRM-577): Remove this variable because it is a temporary workaround.
 * We have to rework related API requests so the work with pagination correctly.
 * We used to have ability to get all available items.
 */
export const defaultPaginationOptions = new PaginationData(1, MAX_SERVER_PAGE_SIZE);
